import React from "react"
import PropTypes from "prop-types"
import { CoffeeLoading } from 'react-loadingg';
import CheckAdditionalForm from './CheckAdditionalForm';
import CountrySelect from './CountrySelect';
import DocUploadForm from './DocUploadForm';
import TinkLinkForm from './TinkLinkForm';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TextField, Grid, Card, CardContent, Button, Slider, Fade, FormControlLabel, FormControl,
  RadioGroup, Radio, IconButton, Stepper, Step, StepLabel, Select, MenuItem, InputLabel } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AnimatedCheckmark, AnimatedCancelmark } from "./animated-icons";

import I18n from '../packs/i18n/translations';
I18n.translations || (I18n.translations = {});

const parse = require('html-react-parser');
const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class LettingApplication extends React.Component {
  constructor(props) {
    super(props);

    // TODO: remove
    const appId = localStorage.getItem('sproveApplicationId') || props.lettingApplicationId;
    appId && fetch(`${props.property.lettingApplicationUrl}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        authenticity_token: props.authenticityToken,
        id: appId
      })
    }).then(resp => {
      if (!resp.ok) {
        throw Error(resp.statusText);
      }

      return resp.json();
    }).then(resp => {
      this.setDefaults(resp.letting_application);
    }).catch(error => console.log(error));

    this.state = {
      locale: this.props.locale || 'en',
      loading: false,
      iframeState: 0,
      callbackUrl: this.props.callbackUrl,
      currentStep: this.hasApplicationsSubmitted() && 'quick_apply' || 'guide',
      country: 'NO',
      loadingText: I18n.t('properties.show.loading_text.default'),
      quickApplyId: props.tenant.lettingApplications?.[0]?.[1],
    };
  }

  setDefaults = (lettingApplication) => {
    this.setState({ lettingApplication: lettingApplication, tenants: lettingApplication.letting_application_tenants, currentStep: 'guide' })
  }

  onAccountClaim = (event) => {
    event.preventDefault();
    const tenant = this.state.tenants[0];

    fetch('/tenants/claim_account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
              tenant: {
                name: tenant.full_name,
                email: this.state.accountEmail,
              },
              letting_application: {
                id: this.state.lettingApplication.id,
              },
              authenticity_token: this.props.authenticityToken,
            })
    })
    .then(respJSON => respJSON.json())
    .then(resp => {
        switch(resp.status) {
          case 'created': {
            this.setState({ accountClaimed: true });
            break;
          }
          case 'error': {
            alert(resp.message);
            break;
          }
        }
      })
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  }

  updateCheck = (inputs) => {
    console.log(inputs);

    fetch(`${this.props.property.checkUrl}/${this.state.checkId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
              check: inputs,
              authenticity_token: this.props.authenticityToken,
            }),
    }).then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }

      return respJSON.json()
    })
    .then(resp => {
      switch (resp.status) {
        case 'updated': {
          this.setState({ step: 3, status: 'completed' });
          break;
        }
        case 'error': {
          alert(resp.message);
          break;
        }
      }
    })
    .catch(error => alert(error));
  }

  renderLoading() {
    if (this.state.loading) {
      return (
        <div className="justify-content-center align-items-center text-center d-flex flex-column" id="property_check_loading">
          <div className="d-flex" style={{ minHeight: "100px" }}>
            <CoffeeLoading color="#4579aa" />
          </div>
          <div>
            <p>{ this.state.loadingText }</p>
          </div>
        </div>
      )
    }
  }

  onApplicationCreate = (lettingApplication) => {
    localStorage.setItem('sproveApplicationId', lettingApplication.id);
    this.setState({ lettingApplication: lettingApplication, currentStep: 'guide' });
  }

  onApplicationSubmit = (event) => {
    event.preventDefault();

    fetch(`${this.props.property.lettingApplicationUrl}/${this.state.lettingApplication.id}/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                authenticity_token: this.props.authenticityToken,
              }),
      }
    ).then(resp => resp.json())
    .then(resp => {
      if (resp.status === 'completed') {
        this.setState({ currentStep: 'completed', accountEmail: this.state.tenants[0].email });
        localStorage.removeItem('sproveApplicationId');
      }
    })
  }

  onApplicationTenantsCreate = (tenants) => {
    this.setState({ tenants: tenants });
  }

  onProceed = (event) => {
    event.preventDefault();

    if (this.state.lettingApplication) {
      this.setState({ currentStep: 'tenants' })
    } else {
      this.setState({ currentStep: 'general' })
    }
  }

  generalInfoCompleted() {
    return !!this.state.lettingApplication?.id;
  }

  tenantsInfoCompleted() {
    return this.state.tenants?.length > 0;
  }

  allStepsCompleted() {
    return this.generalInfoCompleted() && this.tenantsInfoCompleted();
  }

  renderCompletedStatus(completed) {
    return completed && <small>{ I18n.t('common.completed') }</small> || <small>{ I18n.t('common.not_completed') }</small>
  }

  hasApplicationsSubmitted() {
    return this.props.tenant.id && this.props.tenant.lettingApplications?.length > 0;
  }

  onQuickApply = (event) => {
    event.preventDefault();

    fetch(this.props.property.lettingApplicationUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
              letting_application_quick_apply_id: this.state.quickApplyId,
              authenticity_token: this.props.authenticityToken,
            })
    })
    .then(respJSON => respJSON.json())
    .then(resp => {
      this.setState({ submitEnabled: true });

      switch(resp.status) {
        case 'created':
        case 'exists': {
          this.setDefaults(resp.letting_application);
          break;
        }
        case 'error': {
          alert(resp.message);
          break;
        }
      }
    })
    .catch(err => {
      this.setState({ submitEnabled: true });

      console.log(err)
    });
  }

  renderIntro() {
    return (
      <React.Fragment>
        <h1 className="mt-4 text-center">{ I18n.t('letting_applications.new.guide.title') },</h1>
        <p>
          { parse(I18n.t('letting_applications.new.guide.property_into', { name: this.props.property.title })) } { this.props.property.adCode ? <a rel="noopener noreferrer" target="_blank" href={ `https://www.finn.no/ad.html?finnkode=${this.props.property.adCode}` }>({ I18n.t('letting_applications.new.guide.ad_link_title') })</a> : null }
        </p>
      </React.Fragment>
    )
  }

  renderQuickApply() {
    if (this.state.currentStep === 'quick_apply') {
      return (
        <React.Fragment>
          { this.renderIntro() }
          <p>{ parse(I18n.t('tenant.letting_applications.new.quick_apply.description')) }</p>
          <FormControl fullWidth variant="outlined" className="mb-2">
            <InputLabel id="quickApplyId">{ I18n.t('tenant.letting_applications.new.quick_apply.select_application') }</InputLabel>

            <Select labelId="quickApplyId" label={ I18n.t('tenant.letting_applications.new.quick_apply.select_application') } name="quickApplyId" variant="outlined" value={this.state.quickApplyId} onChange={this.handleInputChange}>
              {
                this.props.tenant.lettingApplications.map(value => <MenuItem value={ value[1] } key={ value[1] }>{ value[0] }</MenuItem>)
              }
            </Select>
          </FormControl>
          <Button fullWidth variant="contained" color="secondary" className="mb-4" onClick={ this.onQuickApply }>{ I18n.t('letting_applications.new.quick_apply.submit') }</Button>
          <div className="strike mb-4"><span>OR</span></div>
          <Button fullWidth variant="contained" color="primary" className="mb-4" onClick={ () => this.setState({ currentStep: 'guide' }) }>{ I18n.t('letting_applications.new.quick_apply.skip') }</Button>
        </React.Fragment>
      )
    }
  }

  renderGuide() {
    if (this.state.currentStep === 'guide') {
      return (
        <React.Fragment>
          { this.renderIntro() }
          <p> { I18n.t('letting_applications.new.guide.description') } </p>
          <Card className="mb-4" elevation={3} >
            <CardContent>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <div>
                  <div>1. { I18n.t('letting_applications.new.general.title') }</div>
                  { this.renderCompletedStatus(this.generalInfoCompleted()) }
                </div>
                {
                  this.generalInfoCompleted() &&
                    <IconButton aria-label="delete" color="primary" onClick={ () => this.setState({ currentStep: 'general' }) }>
                      <EditIcon fontSize="small" />
                    </IconButton>
                }
              </Grid>
            </CardContent>
          </Card>
          <Card className="mb-4" elevation={3}>
            <CardContent>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <div className={ this.generalInfoCompleted() ? '' : 'text-muted' }>
                  <div>2. { I18n.t('letting_applications.new.tenants.title') }</div>
                  { this.renderCompletedStatus(this.tenantsInfoCompleted()) }
                </div>
                {
                  this.tenantsInfoCompleted() &&
                    <IconButton aria-label="delete" color="primary" onClick={ () => this.setState({ currentStep: 'tenants' }) }>
                      <EditIcon fontSize="small" />
                    </IconButton>
                }
              </Grid>
            </CardContent>
          </Card>
          {
            this.allStepsCompleted() &&
              <div>
                <p>{ I18n.t('letting_applications.new.submit.description') }</p>
                <Button variant="contained" color="secondary" className="mb-4" onClick={ this.onApplicationSubmit }>{ I18n.t('letting_applications.new.submit.button_text') }</Button>
              </div> ||
              <Button variant="contained" color="secondary" className="mb-4" onClick={ this.onProceed }>{ I18n.t('letting_applications.new.steps.proceed') }</Button>
          }
        </React.Fragment>
      )
    }
  }

  renderGeneral() {
    if (this.state.currentStep === 'general') {
      return (
        <React.Fragment>
          <h1 className="mt-4 text-center">{ I18n.t('letting_applications.new.general.title') }</h1>
          <p> { I18n.t('letting_applications.new.general.description') } </p>
          <GeneralInformation onSubmit={ this.onApplicationCreate } lettingApplication={ this.state.lettingApplication } {...this.props} />
        </React.Fragment>
      )
    }
  }

  renderTenants() {
    if (this.state.currentStep === 'tenants') {
      return (
        <React.Fragment>
          <h1 className="mt-4 text-center">{ I18n.t('letting_applications.new.tenants.title') }</h1>
          <p> { I18n.t('letting_applications.new.tenants.description') } </p>
          <TenantsInformation onSubmit={ this.onApplicationTenantsCreate } onBack={ () => this.setState({ currentStep: 'guide' }) } tenants={ this.state.tenants } lettingApplication={ this.state.lettingApplication } {...this.props} />
        </React.Fragment>
      )
    }
  }

  // renderAISP() {
  //   if (this.state.step === 1) {
  //     return (
  //       <React.Fragment>
  //         <p> { parse(I18n.t('properties.show.steps.step2.tips.subtitle')) } </p>
  //         <CountrySelect
  //           label={ I18n.t('properties.show.steps.step2.select_country') }
  //           value={ this.state.country }
  //           options={ Object.entries(I18n.t('properties.show.steps.step2.tink_countries')).map((entry) => [entry[0], <span><i className={ `flag-icon flag-icon-${entry[0].toLowerCase()} mr-2` } /> { entry[1] }</span>]) }
  //           onChange={ this.handleCountryChange }
  //         />
  //         <div className="d-flex justify-content-center mt-4">
  //           <iframe src={ `${this.props.bankIframeUrl}&market=${this.state.country}` } className="mx-auto" width="100%" height="450" frameBorder="0" />
  //         </div>
  //       </React.Fragment>
  //     )
  //   }
  // }

  renderAdditionalInfo() {
    if (this.state.step === 2) {
      return (
        <CheckAdditionalForm fileUploadUrl={ this.props.fileUploadUrl } onSubmit={ this.updateCheck } />
      )
    }
  }

  renderThankYou() {
    if (this.state.currentStep === 'completed') {
      return (
        <React.Fragment>
          <div className="d-flex justify-content-center my-4">
            <AnimatedCheckmark timeout={ 800 } />
          </div>
          <h2>{ parse(I18n.t('letting_applications.new.submit.header')) }</h2>
          <p>{ I18n.t('letting_applications.new.submit.claim_account.text') }</p>
          { this.renderTenantForm() }
          {
            this.props.tenant?.id &&
              <Button fullWidth variant="contained" color="primary" className="mb-4" onClick={ () => window.location = '/' }>
                { I18n.t('tenant.letting_applications.new.exists_button') }
              </Button> ||
              <Button fullWidth variant="contained" color="secondary" className={ `mb-4 ${this.state.showTenantForm ? 'd-none' : '' }` } onClick={ () => this.setState({ showTenantForm: true }) }>
                { I18n.t('letting_applications.new.submit.claim_account.button') }
              </Button>
          }
        </React.Fragment>
      )
      //   case 'cancelled': {
      //     return (
      //       <React.Fragment>
      //         <div className="d-flex justify-content-center my-4">
      //           <AnimatedCancelmark />
      //         </div>
      //         { parse(I18n.t('properties.show.steps.step3.header.cancelled')) }
      //         <p>{ I18n.t('properties.show.steps.step3.restart.text') }</p>
      //         <button className="btn btn-primary btn-block mb-4" onClick={ () => window.location.reload() }>{ I18n.t('properties.show.steps.step3.restart.button') }</button>
      //       </React.Fragment>
      //     )
      //     break;
      //   }
    }
  }

            // <label>{ I18n.t('tenants.email') }</label>
            // <input className="form-control" type="text" name="email" value={ this.state.tenant.email } onChange={ this.handleTenantInput } />
  renderTenantForm() {
    if (this.state.accountClaimed) {
      return (
        <p className="text-success">{ I18n.t('properties.show.steps.step3.claim_account.success') }</p>
      )
    }

    if (this.state.showTenantForm) {
      return (
        <form onSubmit={ this.onAccountClaim }>
          <div className="form-group">
            <TextField fullWidth label={ I18n.t('tenants.email') } name="accountEmail" variant="outlined" value={ this.state.accountEmail } onChange={this.handleInputChange} />
          </div>
          <Button fullWidth type="submit" variant="contained" color="secondary" className="mb-4" disabled={ !emailPattern.test(this.state.accountEmail) }>
            { I18n.t('letting_applications.new.submit.claim_account.save_profile') }
          </Button>
        </form>
      )
    }
  }

  render () {
    I18n.locale = this.state.locale;

    return (
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <div className="property-check bg-white">
            <div className="row">
              { this.renderLoading() }
              <div className="col-lg-8 offset-lg-2 col-10 offset-1">
                <ThemeProvider theme={theme}>
                  { this.renderQuickApply() }
                  { this.renderGuide() }
                  { this.renderGeneral() }
                  { this.renderTenants() }
                  { this.renderThankYou() }
                </ThemeProvider>
              </div>
            </div>
          </div>
          <div className="mt-4 px-4">
            <a href="#" className={`font-14 border-bottom text-dark pb-1 ${ this.state.locale === 'en' && "border-info"} mr-2` || ''} onClick={ () => this.setState({ locale: 'en' }) }>English</a>
            <a href="#" className={`font-14 border-bottom text-dark pb-1 ${ this.state.locale === 'nb' && "border-info"} mr-2` || ''} onClick={ () => this.setState({ locale: 'nb' }) }>Bokmål</a>
          </div>
        </div>
      </div>
    );
  }
}

const formatDate = (date) => date && new Date(date.getTime() - date.getTimezoneOffset() * 60000);

class GeneralInformation extends React.Component {
  constructor(props) {
    super(props);

    this.preriodMarks = I18n.t('letting_applications.period_options');
    const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1));

    this.state = {
      handoverDate: this.props.lettingApplication?.handover_at && new Date(this.props.lettingApplication.handover_at) || nextMonth,
      period: this.props.lettingApplication?.period_months || 12,
      pets: this.props.lettingApplication?.pets || false,
      petType: this.props.lettingApplication?.pet_type || '',
      smoking: this.props.lettingApplication?.smoking || false,
      depositType: this.props.lettingApplication?.deposit_type && (this.props.depositTypes.includes(this.props.lettingApplication.deposit_type) && this.props.lettingApplication.deposit_type || 'other') || '',
      depositTypeCustom: this.props.lettingApplication?.deposit_type || '',
      validUntilDate: this.props.lettingApplication?.valid_until && new Date(this.props.lettingApplication.valid_until) || nextMonth,
      privacyAccepted: false,
      termsAccepted: false,
      submitEnabled: true,
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.name === 'pets' || target.name === 'smoking' ||
                target.name === 'termsAccepted' || target.name === 'privacyAccepted' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitEnabled: false });

    const url = this.props.lettingApplication?.id && `${this.props.property.lettingApplicationUrl}/${this.props.lettingApplication.id}` || this.props.property.lettingApplicationUrl;
    const method = this.props.lettingApplication?.id && 'PUT' || 'POST';

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
              letting_application: {
                handover_at:  this.state.handoverDate,
                period_months: this.state.period,
                pets: this.state.pets,
                pet_type: this.state.petType,
                smoking: this.state.smoking,
                valid_until: this.state.validUntilDate,
                deposit_type: this.state.depositType == 'other' && this.state.depositTypeCustom || this.state.depositType,
              },
              authenticity_token: this.props.authenticityToken,
            })
    })
    .then(respJSON => respJSON.json())
    .then(resp => {
      this.setState({ submitEnabled: true });

      switch(resp.status) {
        case 'created':
        case 'updated':
        case 'exists': {
          this.props.onSubmit(resp.letting_application);
          break;
        }
        case 'error': {
          alert(resp.message);
          break;
        }
      }
    })
    .catch(err => {
      this.setState({ submitEnabled: true });

      console.log(err)
    });
  }

  isSubmitEnabled() {
    return this.state.submitEnabled &&
      (this.props.lettingApplication?.id || this.state.privacyAccepted && this.state.termsAccepted) &&
      this.state.period >= this.preriodMarks[0].value && this.state.period <= this.preriodMarks[this.preriodMarks.length - 1].value &&
      (!this.state.pets || this.state.petType.length > 2) &&
      this.state.depositType.length > 1 && (this.state.depositType != 'other' || this.state.depositTypeCustom.length > 3) &&
      !isNaN(this.state.handoverDate?.getTime()) && this.state.handoverDate > (new Date);
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>

        <div className="form-group">
          <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <KeyboardDatePicker
              autoOk
              fullWidth
              minDate={ new Date }
              variant="inline"
              inputVariant="outlined"
              label={ I18n.t('letting_applications.handover_at') }
              format="dd.MM.yyyy"
              InputAdornmentProps={{ position: "start" }}
              value={ this.state.handoverDate }
              onChange={ (date) => this.setState({ handoverDate: formatDate(date) }) } />
          </MuiPickersUtilsProvider>
        </div>

        <div className="form-group">
          <label>{ I18n.t('letting_applications.period') }</label>
          <Slider
            aria-labelledby="discrete-slider-custom"
            step={ 6 }
            min={ this.preriodMarks[0].value }
            max={ this.preriodMarks[ this.preriodMarks.length - 1].value }
            valueLabelDisplay="auto"
            marks={ this.preriodMarks }
            value={ this.state.period }
            onChange={ (_, value) => this.setState({ period: value }) }
          />
        </div>

        <div className="form-group">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input data-privacy" id="customSwitch3" name="pets" checked={this.state.pets} onChange={this.handleInputChange} />
            <label className="custom-control-label" htmlFor="customSwitch3"> { I18n.t('letting_applications.pets') } </label>
          </div>
        </div>

        <Fade in={ this.state.pets } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="form-group">
            <TextField fullWidth label={ I18n.t('letting_applications.pet_type') } name="petType" variant="outlined" value={this.state.petType} onChange={this.handleInputChange} />
          </div>
        </Fade>

        <div className="form-group">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input data-privacy" id="customSwitch4" name="smoking" checked={this.state.smoking} onChange={this.handleInputChange} />
            <label className="custom-control-label" htmlFor="customSwitch4"> { I18n.t('letting_applications.smoking') } </label>
          </div>
        </div>

        <div className="form-group radio-buttons mb-4">
          <label>{ I18n.t('letting_applications.deposit_type') }</label>
          <RadioGroup aria-label="depositType" name="depositType" value={ this.state.depositType } onChange={this.handleInputChange}>
            {
              this.props.depositTypes.map ((value, idx) => <FormControlLabel key={ idx } value={ value } control={<Radio />} label={ I18n.t(value, { scope: 'letting_applications.deposit_types' }) } /> )
            }
            <FormControlLabel key={ 'other' } value={ 'other' } control={<Radio />} label={ I18n.t('other', { scope: 'letting_applications.deposit_types' }) } />
          </RadioGroup>
          <Fade in={ this.state.depositType == 'other' } mountOnEnter={ true } unmountOnExit={ true }>
            <TextField fullWidth label={ I18n.t('letting_applications.deposit_type_other_label') } name="depositTypeCustom" variant="outlined" value={this.state.depositTypeCustom} onChange={this.handleInputChange} />
          </Fade>
        </div>


        <div className="form-group">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              fullWidth
              minDate={ new Date }
              variant="inline"
              inputVariant="outlined"
              label={ I18n.t('letting_applications.valid_until') }
              format="dd.MM.yyyy"
              InputAdornmentProps={{ position: "start" }}
              value={ this.state.validUntilDate }
              onChange={ (date) => this.setState({ validUntilDate: formatDate(date) }) } />
          </MuiPickersUtilsProvider>
        </div>

        {
          !this.props.lettingApplication?.id &&
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input btn-success data-privacy" id="customSwitch1" name="privacyAccepted" checked={this.state.privacyAccepted} onChange={this.handleInputChange} />
                <label className="custom-control-label" htmlFor="customSwitch1"> { parse(I18n.t('privacy', { scope: 'properties.show.policies' })) } </label>
              </div>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input data-privacy" id="customSwitch2" name="termsAccepted" checked={this.state.termsAccepted} onChange={this.handleInputChange} />
                <label className="custom-control-label" htmlFor="customSwitch2"> { parse(I18n.t('terms_of_use', { scope: 'properties.show.policies' })) } </label>
              </div>
            </div>
        }
        <Button variant="contained" color="primary" className="mb-4" type="submit" onClick={ this.onProceed } disabled={ !(this.isSubmitEnabled()) }>{ I18n.t('letting_applications.save_buton') }</Button>
      </form>
    );
  }
}

class Tenant {
  constructor(attrs) {
    this.id = attrs.id || undefined
    this.full_name = attrs.fullName
    this.phone_number = attrs.phoneNumber
    this.address = attrs.address
    this.email = attrs.email
    this.birth_date = attrs.birthDate

    if (attrs.employer) {
      this.employer = attrs.employer
      this.position = attrs.position
      this.employed_from = attrs.employedFrom
      this.employment_type = attrs.employmentType
      this.wage = attrs.wage
    }

    if (attrs.student) {
      this.student = attrs.student
      this.studied_from = attrs.studiedFrom
      this.studied_at = attrs.studiedAt
    }

    this.references = attrs.references
    this.documents = attrs.documents

    this._destroy = attrs._destroy
  }
}

class TenantsInformation extends React.Component {
  constructor(props) {
    super(props);

    // Tenant attributes
    // full_name
    // phone_number
    // address
    // email
    // birth_date
    // letting_application_id
    // employer
    // position
    // employed_from
    // student
    // studied_from
    // studied_at
    // wage
    this.state = {
      tenants: props.tenants,
    };
  }

  lettingApplicationPath = () => `${this.props.property.lettingApplicationUrl}/${this.props.lettingApplication.id}`

  addOrUpdateTenant = (tenantData) => {
    fetch(this.lettingApplicationPath(), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
              letting_application: {
                letting_application_tenants_attributes: [new Tenant(tenantData)],
              },
              authenticity_token: this.props.authenticityToken,
            })
    })
    .then(respJSON => respJSON.json())
    .then(resp => {
      switch(resp.status) {
        case 'updated': {
          let tenants = resp.letting_application.letting_application_tenants;
          this.setState({ tenants: tenants, currentTenant: undefined });
          this.props.onSubmit(tenants);

          break;
        }
        case 'error': {
          alert(resp.message);

          break;
        }
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  deleteTenant = (tenantId) => {
    this.addOrUpdateTenant({ id: tenantId, _destroy: 1 });
  }

  render() {
    if (this.state.tenants?.length > 0) {
      if (this.state.currentTenant !== undefined) {
        return (
          <ApplicationTenantForm onSubmit={ this.addOrUpdateTenant } tenant={ this.state.currentTenant } fileUploadUrl={ this.props.fileUploadUrl } bankIframeUrl={ this.props.bankIframeUrl } callbackUrl={ `${this.lettingApplicationPath()}/oauth_callback` } />
        );
      } else {
        return (
          <React.Fragment>
            {
              this.state.tenants.map((tenant) => {
                return (
                  <Card className="mb-4" elevation={3} key={ tenant.id }>
                    <CardContent>
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <div>
                          <div>{ tenant.full_name }</div>
                        </div>
                        <div>
                          <IconButton aria-label="delete" color="primary" onClick={ () => this.setState({ currentTenant: tenant }) }>
                            <EditIcon fontSize="small" />
                          </IconButton>

                          <IconButton aria-label="delete" className="text-danger" onClick={ () => this.deleteTenant(tenant.id) }>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                )
              })
            }
            <Grid container direction="row" justify="space-between" alignItems="center" className="mb-4">
              <Button variant="contained" color="secondary" onClick={ () => this.setState({ currentTenant: {} }) }>{ I18n.t('letting_applications.new.tenants.add') }</Button>
              <Button variant="contained" color="primary" onClick={ this.props.onBack }>{ I18n.t('letting_applications.new.tenants.done') }</Button>
            </Grid>
          </React.Fragment>
        )
      }
    } else {
      return (
        <ApplicationTenantForm
          onSubmit={ this.addOrUpdateTenant }
          fileUploadUrl={ this.props.fileUploadUrl }
          bankIframeUrl={ this.props.bankIframeUrl }
          callbackUrl={ `${this.lettingApplicationPath()}/oauth_callback` } />
      );
    }
  }
}

const isValidEmail = (email) => emailPattern.test(email);

class ApplicationTenantForm extends React.Component {
  constructor(props) {
    super(props);

    this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    this.state = {
      id: props.tenant?.id || null,
      fullName: props.tenant?.full_name || '',
      phoneNumber: props.tenant?.phone_number || '',
      address: props.tenant?.address || '',
      email: props.tenant?.email || '',
      birthDate: props.tenant?.birth_date && new Date(props.tenant.birth_date) || null,
      employed: !!props.tenant?.employer,
      employer: props.tenant?.employer || '',
      position: props.tenant?.position || '',
      employmentType: props.tenant?.employment_type || '',
      employedFrom: props.tenant?.employment_from && new Date(props.tenant.employment_from) || new Date,
      student: props.tenant?.student || false,
      studiedAt: props.tenant?.studied_at || '',
      studiedFrom: props.tenant?.studied_from && new Date(props.tenant.studied_from) || new Date,
      wage: props.tenant?.wage || '',

      hideOtherFields: !props.tenant?.phone_number,
      activeStep: 0,
    };

    this.docsForm = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = (name === 'student' || name == 'employed') ? target.checked : target.value;

    if (name == 'phoneNumber') {
      value = value.replace(/\D/,'').substring(0,8);

      if (value.length == 8) { this.handleUserInfo(value) }
    }

    this.setState({
      [name]: value
    });
  }

  attachDocs = (inputs) => {
    let tenantData = this.state;
    tenantData = {...tenantData, ...inputs};
    console.log(tenantData);

    this.props.onSubmit(tenantData);
  }

  handleDocsUpload = (event) => {
    event.preventDefault();

    if (this.isSubmitEnabled()) {
      this.docsForm.current.handleSubmit();
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.isSubmitEnabled()) {
      this.props.onSubmit(this.state);
    }
  }

  handleUserInfo = (phoneNumber) => {
    if (String(phoneNumber).length !== 8) {
      alert('A phone number submitted is wrong: ' + this.state.phoneNumber);
    } else {
      fetch(`/user_info/?phone=${phoneNumber}`)
      .then(respJSON => respJSON.json())
      .then(resp => {
        this.setState({ phoneNumber: resp.phone, fullName: (resp.name || ''), address: (resp.address || ''), hideOtherFields: false });
      })
      .catch(error => this.setState({ fullName: '', address: '', hideOtherFields: false }));
    }
  }

  isSubmitEnabled() {
    return this.isStep1Valid() && this.isStep2Valid();
  }

  isStep1Valid() {
    return this.state.address?.length > 8 && this.state.fullName?.length > 4 && isValidEmail(this.state.email) && this.state.birthDate?.getTime && !isNaN(this.state.birthDate.getTime()) && this.state.birthDate <= this.maxDate;
  }

  isStep2Valid() {
    return (!this.state.employed || this.state.employer?.length > 3 && this.state.position?.length > 2 && this.state.wage?.toString && this.state.wage.toString().length > 3 &&
      this.state.employedFrom?.getTime && !isNaN(this.state.employedFrom.getTime()) && this.state.employmentType?.length > 1) &&
      (!this.state.student || this.state.studiedAt?.length > 2 && this.state.studiedFrom?.getTime && !isNaN(this.state.studiedFrom.getTime()));
  }

  renderStep1 () {
    return(
      <React.Fragment>
        <div className="form-group">
          <TextField fullWidth label={ I18n.t('tenants.phone_number') } name="phoneNumber" variant="outlined" value={this.state.phoneNumber} onChange={this.handleInputChange} />
        </div>

        {
          !this.state.hideOtherFields &&
            <React.Fragment>
              <div className="form-group">
                <TextField fullWidth label={ I18n.t('tenants.name') } name="fullName" variant="outlined" value={this.state.fullName} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <TextField fullWidth label={ I18n.t('tenants.address') } name="address" variant="outlined" value={this.state.address} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <TextField fullWidth label={ I18n.t('tenants.email') } name="email" variant="outlined" value={this.state.email} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    maxDate={ this.maxBDate }
                    variant="inline"
                    inputVariant="outlined"
                    label={ I18n.t('tenants.birth_date') }
                    format="dd.MM.yyyy"
                    InputAdornmentProps={{ position: "start" }}
                    value={ this.state.birthDate }
                    onChange={ (date) => this.setState({ birthDate: formatDate(date) }) } />
                </MuiPickersUtilsProvider>
              </div>
            </React.Fragment>
        }
      </React.Fragment>
    )
  }

  renderStep2 () {
    return(
      <React.Fragment>
        <div className="form-group">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input data-privacy" id="customSwitch5" name="employed" checked={this.state.employed} onChange={this.handleInputChange} />
            <label className="custom-control-label" htmlFor="customSwitch5"> { I18n.t('letting_application_tenants.employed') } </label>
          </div>
        </div>

        {
          this.state.employed &&
            <React.Fragment>
              <div className="form-group">
                <TextField fullWidth label={ I18n.t('letting_application_tenants.employer') } name="employer" variant="outlined" value={this.state.employer} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <TextField fullWidth label={ I18n.t('letting_application_tenants.position') } name="position" variant="outlined" value={this.state.position} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="employmentType">{ I18n.t('letting_application_tenants.employment_type') }</InputLabel>
                  <Select labelId="employmentType" label={ I18n.t('letting_application_tenants.employment_type') } name="employmentType" variant="outlined" value={this.state.employmentType} onChange={this.handleInputChange}>
                    {
                      Object.entries(I18n.t('letting_application_tenants.employment_types')).map(value => <MenuItem value={ value[0] } key={ value[0] }>{ value[1] }</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>

              <div className="form-group">
                <TextField fullWidth label={ I18n.t('letting_application_tenants.wage') } name="wage" variant="outlined" value={this.state.wage} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label={ I18n.t('letting_application_tenants.employed_from') }
                    format="dd.MM.yyyy"
                    InputAdornmentProps={{ position: "start" }}
                    value={ this.state.employedFrom }
                    onChange={ (date) => this.setState({ employedFrom: formatDate(date) }) } />
                </MuiPickersUtilsProvider>
              </div>
            </React.Fragment>
        }

        <div className="form-group">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input data-privacy" id="customSwitch6" name="student" checked={this.state.student} onChange={this.handleInputChange} />
            <label className="custom-control-label" htmlFor="customSwitch6"> { I18n.t('letting_application_tenants.student') } </label>
          </div>
        </div>

        {
          this.state.student &&
            <React.Fragment>
              <div className="form-group">
                <TextField fullWidth label={ I18n.t('letting_application_tenants.studied_at') } name="studiedAt" variant="outlined" value={this.state.studiedAt} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label={ I18n.t('letting_application_tenants.studied_from') }
                    format="dd.MM.yyyy"
                    InputAdornmentProps={{ position: "start" }}
                    value={ this.state.studiedFrom }
                    onChange={ (date) => this.setState({ studiedFrom: formatDate(date) }) } />
                </MuiPickersUtilsProvider>
              </div>
            </React.Fragment>

        }
      </React.Fragment>
    )
  }

  renderStep3 () {
    return (
      <div className="mb-4">
        <TinkLinkForm
          bankIframeUrl={ this.props.bankIframeUrl }
          onSubmit={ this.handleNext }
          callbackUrl={ this.props.callbackUrl }
          tenantName={ this.state.fullName } />
      </div>
    )
  }

  renderStep4 () {
    return (
      <div className="mb-4">
        <DocUploadForm fileUploadUrl={ this.props.fileUploadUrl } onSubmit={ this.attachDocs } ref={ this.docsForm } />
      </div>
    )
  }

  renderSteps () {
    switch(this.state.activeStep) {
      case 0: {
        return(this.renderStep1());
        break;
      }
      case 1: {
        return(this.renderStep2());
        break;
      }
      case 2: {
        return(this.renderStep3());
        break;
      }
      case 3: {
        return(this.renderStep4());
        break;
      }
    }
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  isStepEnabled() {
    switch(this.state.activeStep) {
      case 0: {
        return(this.isStep1Valid());
        break;
      }
      case 1: {
        return(this.isStep2Valid());
        break;
      }
      case 2: {
        return true;
        break;
      }
      case 3: {
        break;
      }
    }
  }

  render() {
    // const steps = ['Personal', 'Occupation', 'Verification', 'Misc'];
    const steps = [I18n.t('letting_applications.new.tenants.step_titles.personal'), I18n.t('letting_applications.new.tenants.step_titles.occupation'),
                  I18n.t('letting_applications.new.tenants.step_titles.verification'), I18n.t('letting_applications.new.tenants.step_titles.misc')];

    return (
      <div>
        <Stepper alternativeLabel activeStep={this.state.activeStep} className="px-0">
          {
            steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))
          }
        </Stepper>
        { this.renderSteps() }
        <div className="mb-4">
          <Button disabled={this.state.activeStep === 0} onClick={ this.handleBack }>{ I18n.t('letting_applications.new.tenants.form.back') }</Button>
          {
            this.state.activeStep === steps.length - 1 &&
              <Button variant="contained" color="primary" onClick={ this.handleDocsUpload } disabled={ !this.isSubmitEnabled() }>
                { I18n.t('letting_applications.new.tenants.form.save') }
              </Button> ||
              <Button variant="contained" color="primary" onClick={ this.handleNext } disabled={ !this.isStepEnabled() }>
                { this.state.activeStep == 2 && I18n.t('letting_applications.new.tenants.form.skip') || I18n.t('letting_applications.new.tenants.form.next') }
              </Button>
          }
        </div>
      </div>
    );
  }
}



const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4579aa',
    },
    secondary: {
      main: '#499b33',
    },
  },
});

LettingApplication.propTypes = {
  authenticityToken: PropTypes.string,
  bankIframeUrl: PropTypes.string,
  callbackUrl: PropTypes.string,
  locale: PropTypes.string,
  property: PropTypes.object,
  fileUploadUrl: PropTypes.string,
  depositTypes: PropTypes.array,
};
export default LettingApplication
