import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import I18n from '../packs/i18n/translations';
// I18n.translations || (I18n.translations = {});

const parse = require('html-react-parser');
const dateFormat = require('dateformat');
const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class CheckForm extends React.Component {
  constructor(props) {
    super(props);

    this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    this.state = {
      phone: '',
      name: '',
      address: '',
      email: '',
      birthDate: this.maxDate,
      hideOtherFields: true,
      privacyAccepted: false,
      termsAccepted: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.name === 'termsAccepted' || target.name === 'privacyAccepted' ? target.checked : target.value;
    const name = target.name;

    if (name == 'phone') { value = value.replace(/\D/,'').substring(0,8) }

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (String(this.state.phone).length !== 8) {
      alert('A phone number submitted is wrong: ' + this.state.phone);
    } else {
      if (this.isSubmitEnabled()) {
        let userData = this.state;
        userData.birthDate = dateFormat(userData.birthDate, 'dd.mm.yyyy');
        this.props.onSubmit(userData, event);
      } else {
        fetch(`/user_info/?phone=${this.state.phone}`)
          .then(respJSON => respJSON.json())
          .then(resp => {
            this.setState({ ...resp, hideOtherFields: false });
          });
      }
    }
  }

  isSubmitEnabled() {
    return this.state.privacyAccepted && this.state.termsAccepted && this.state.address.length > 8 && this.state.name.length > 4 && this.isValidEmail() && !isNaN(this.state.birthDate?.getTime()) && this.state.birthDate <= this.maxDate;
  }

  isValidEmail() {
    return emailPattern.test(this.state.email);
  }

        // <div className="form-group">
        //   <label>{ I18n.t('tenants.phone_number') }:</label>
        //   <input className="form-control" type="text" name="phone" value={this.state.phone} onChange={this.handleInputChange} />
        // </div>
  render() {
    return (
      <form onSubmit={this.handleSubmit}>

        <div className="form-group">
          <TextField fullWidth label={ I18n.t('tenants.phone_number') } name="phone" variant="outlined" value={this.state.phone} onChange={this.handleInputChange} />
        </div>

        {
          !this.state.hideOtherFields &&
            <React.Fragment>
              <div className="form-group">
                <TextField fullWidth label={ I18n.t('tenants.name') } name="name" variant="outlined" value={this.state.name} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <TextField fullWidth label={ I18n.t('tenants.address') } name="address" variant="outlined" value={this.state.address} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <TextField fullWidth label={ I18n.t('tenants.email') } name="email" variant="outlined" value={this.state.email} onChange={this.handleInputChange} />
              </div>

              <div className="form-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    maxDate={ this.maxDate }
                    variant="inline"
                    inputVariant="outlined"
                    label={ I18n.t('tenants.birth_date') }
                    format="dd.MM.yyyy"
                    InputAdornmentProps={{ position: "start" }}
                    value={ this.state.birthDate }
                    onChange={ (date) => this.setState({ birthDate: date }) } />
                </MuiPickersUtilsProvider>
              </div>

              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input btn-success data-privacy" id="customSwitch1" name="privacyAccepted" checked={this.state.privacyAccepted} onChange={this.handleInputChange} />
                  <label className="custom-control-label" htmlFor="customSwitch1"> { parse(I18n.t('privacy', { scope: 'properties.show.policies' })) } </label>
                </div>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input data-privacy" id="customSwitch2" name="termsAccepted" checked={this.state.termsAccepted} onChange={this.handleInputChange} />
                  <label className="custom-control-label" htmlFor="customSwitch2"> { parse(I18n.t('terms_of_use', { scope: 'properties.show.policies' })) } </label>
                </div>
              </div>
            </React.Fragment>
        }

        { this.state.hideOtherFields && <input className="btn btn-primary mb-4" type="submit" value={ I18n.t('properties.show.steps.step1.collect_info') } disabled={ this.state.phone.length != 8 }/> ||
            <input className="btn btn-primary mb-4" type="submit" value={ I18n.t('properties.show.steps.step1.proceed') } disabled={ !(this.isSubmitEnabled()) } /> }
      </form>
    );
  }
}
