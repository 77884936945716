    // // data validation page, receive message from Tink or other 3rd party
    // const priceReductionBankIframeContainerId = 'tink_iframe_tenant';
    // const resultLoadingId = 'result_loading';
    // const resultDecliinedId = 'result_declined';
    // const resultApprovedId = 'result_approved';
    // let priceReductionBankIframeContainer = document.getElementById(priceReductionBankIframeContainerId);
    // let resultLoadingContainer = document.getElementById(resultLoadingId);
    // let resultDecliinedContainer = document.getElementById(resultDecliinedId);
    // let resultApprovedContainer = document.getElementById(resultApprovedId);

    // if (typeof(priceReductionBankIframeContainer) != 'undefined' && priceReductionBankIframeContainer != null) {
      // Receive status, code and other messages from Tink
      // var receiveCode = (event) => {
      //   if (event.origin === 'https://link.tink.com') {
      //     let data = JSON.parse(event.data);
      //     switch(data.type) {
      //       case 'status': {
      //         // TODO: add some fancy things?
      //         break;
      //       }
      //       case 'code': {
      //         let code = data.data; // data.type sould be 'code'
      //         let tinkIframe = priceReductionBankIframeContainer.querySelector('iframe');
      //         // console.log(data);

      //         // Hide account aggregation, show calculation
      //         priceReductionBankIframeContainer.classList.add('d-none');
      //         resultLoadingContainer.classList.remove('d-none');

      //         // if (priceReductionBankIframeContainer.dataset.redirectUrl != 'undefined' && priceReductionBankIframeContainer.dataset.redirectUrl != null) {
      //         //   let redirectLink = `${priceReductionBankIframeContainer.dataset.redirectUrl}?code=${code}`;
      //         //   tinkIframe.src = redirectLink;
      //         // }

      //         if (priceReductionBankIframeContainer.dataset.callbackUrl != 'undefined' && priceReductionBankIframeContainer.dataset.callbackUrl != null) {
      //           let callbackLink = `${priceReductionBankIframeContainer.dataset.callbackUrl}?code=${code}`;
      //           fetch(callbackLink)
      //             .then(respJSON => respJSON.json())
      //             .then(resp => {
      //               resultLoadingContainer.classList.add('d-none');
      //               switch(resp.status) {
      //                 case 'ok': {
      //                   // window.location = resp.redirect_url;
      //                   if (resp.price_request.eligible) {
      //                     resultApprovedContainer.classList.remove('d-none');
      //                     let certificateButton = resultApprovedContainer.querySelector('a.btn')[0];
      //                     certificateButton.setAttribute('href', resp.price_request.url);
      //                   } else {
      //                     resultDecliinedContainer.classList.remove('d-none');
      //                     priceReductionBankIframeContainer.dataset.callbackUrl = resp.price_request.add_account_url;
      //                   }
      //                   break;
      //                 }
      //                 case 'error' : {
      //                   alert(resp.message);
      //                   break;
      //                 }
      //               }
      //             })
      //         }

      //         break;
      //       }
      //     }
      //   }
      // }

      // window.addEventListener("message", receiveCode, false);

      // Try again with another account
      // document.querySelector(`#${resultDecliinedId}`).addEventListener('click', (event) => {
      //   event.preventDefault();

      //   if (event.target.classList.contains('btn')) {
      //     resultDecliinedContainer.classList.add('animated', 'fadeOut');
      //     resultDecliinedContainer.addEventListener('animationend', () => {
      //       resultDecliinedContainer.classList.add('d-none');
      //       priceReductionBankIframeContainer.classList.remove('d-none');
      //     });

      //     let tinkIframe = priceReductionBankIframeContainer.querySelector('iframe');
      //     tinkIframe.setAttribute('src', priceReductionBankIframeContainer.dataset.iframeSrc);
      //   }
      // });
    // }

// import tinymce from 'tinymce';
import 'tinymce';
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/autoresize/plugin'

document.addEventListener('DOMContentLoaded', function () {
  var editableContentId = 'tinymce_lm';

  if (document.getElementById(editableContentId)) {
    window.tinymce.init({
      selector: '#tinymce_lm',
      skin: false,
      content_css: false,
      plugins: "autoresize",
      // there: 'modern'
    });
  }
}, false);
