// var ClipboardJS = require('clipboard');
// import ClipboardJS from 'clipboard';
import Clipboard from 'clipboard/dist/clipboard.min.js';
import Stepper from 'bs-stepper';
import toastr from 'toastr';

document.addEventListener('DOMContentLoaded', function () {
  // Copy link button
  // var linkCopy = new ClipboardJS('.copy-btn');
  var linkCopy = new Clipboard('.copy-btn');
  toastr.options = {
    preventDuplicates: true,
    timeOut: 2000,
  }
  linkCopy.on('success', event => {
    var msg = $(event.trigger).data('success') || "You link is copied and ready to be shared with potential tenant."
    toastr["success"](msg);
  }).on('error', _ => {
    var msg = $(event.trigger).data('error') || "You link wasn't copied. Please, select it manually to share."
    toastr["error"](msg);
  })

  let stepperEl = document.querySelector('#check_stepper');
  if (stepperEl) {

    var stepper = new Stepper(stepperEl);
    if (stepperEl && stepperEl.dataset.step) {
      stepper.to(Number.parseInt(stepperEl.dataset.step));
    }

    // Show steps
    stepperEl.classList.remove('d-none');

    var dataPrivacyCheckboxes = document.querySelectorAll('.data-privacy');
    var readyToStep2 = () => Array.from(dataPrivacyCheckboxes).map(chkb => chkb.checked).every(ans => ans) && checkForm.elements['tenant_name'].value.trim().length > 6;
    var step1ButonControl = () => {
      if (readyToStep2()) {
        step1Button.disabled = false;
      } else {
        step1Button.disabled = true;
      }
    }

    var checkForm = document.forms['check'];
    var introInfo = document.getElementById('intro_info');
    var countryInfo = document.getElementById('country_select_info');
    var stepsWrapper = document.getElementById('validation_steps');
    var tenantNameDisplay = document.getElementById('display_tenant_name');

    // data validation page, receive message from Tink or other 3rd party
    const oauthIframeContainerId = 'add_account';
    let oauthIframeContainer = document.getElementById(oauthIframeContainerId);

    // GO to Step 2
    stepperEl.addEventListener('click', function(event) {
      var el = event.target;
      if (el.classList.contains('next-step')) {
        if (el.id === 'step1-next') {
          el.disabled = true;
          if (readyToStep2()) {
            // Hide intro text
            introInfo.classList.add('d-none');
            countryInfo.classList.remove('d-none');
            // stepsWrapper.classList.remove('col-md-8');
            // stepsWrapper.classList.add('col-12');
            let tenantName = document.getElementById('tenant_name').value;
            tenantNameDisplay.innerHTML = tenantName;

            let formData = new FormData();
            formData.append('tenant_name', tenantName);
            formData.append('authenticity_token', checkForm.elements['authenticity_token'].value);

            fetch(el.dataset.checkUrl, {
              method: 'POST',
              body: formData,
            })
            .then(respJSON => respJSON.json())
            .then(resp => {
              switch(resp.status) {
                case 'created': {
                  oauthIframeContainer.dataset.callbackUrl = resp.callback_url;
                  el.disabled = false;
                  stepper.next();

                  break;
                }
                case 'exists': {
                  window.location = resp.redirect_url;
                  break;
                }
                case 'error' : {
                  alert(resp.message);
                  break;
                }
              }
            })
            .catch(error => alert(error));
          }
        }
      }

      // if (event.target.classList.contains('previous-step')) {
      //   stepper.previous();
      // }
    });

    // data validation step form
    var step1Button = document.getElementById('step1-next');
    if (step1Button) {
      checkForm.elements['tenant_name'].addEventListener('input', step1ButonControl)
      Array.from(dataPrivacyCheckboxes).forEach(checkbox => {
        checkbox.addEventListener('change', step1ButonControl)
      })
    }

    // Select country
    var countrySelect = document.getElementById('country_select');

    if (countrySelect) {
      countrySelect.getElementsByClassName('list-group')[0].addEventListener('click', (event) => {
        event.preventDefault();

        var el = event.target;
        oauthIframeContainer.getElementsByTagName('iframe')[0].src = `${oauthIframeContainer.getElementsByTagName('iframe')[0].dataset.src}&market=${event.target.dataset.value}`
        countrySelect.classList.add('d-none');
        oauthIframeContainer.classList.remove('d-none');
        oauthIframeContainer.classList.add('d-flex');
      })
    }

    if (typeof(oauthIframeContainer) != 'undefined' && oauthIframeContainer != null) {
      // Receive status, code and other messages from Tink
      var receiveCode = (event) => {
        if (event.origin === 'https://link.tink.com') {
          let data = JSON.parse(event.data);
          switch(data.type) {
            case 'status': {
              // TODO: add some fancy things?
              break;
            }
            case 'code': {
              let code = data.data; // data.type sould be 'code'
              let tinkIframe = document.querySelector('iframe');
              console.log(data);

              if (oauthIframeContainer.dataset.redirectUrl != 'undefined' && oauthIframeContainer.dataset.redirectUrl != null) {
                let redirectLink = `${oauthIframeContainer.dataset.redirectUrl}?code=${code}`;
                tinkIframe.src = redirectLink;
              }

              if (oauthIframeContainer.dataset.callbackUrl != 'undefined' && oauthIframeContainer.dataset.callbackUrl != null) {
                let callbackLink = `${oauthIframeContainer.dataset.callbackUrl}?code=${code}`;
                window.location.href = callbackLink
              }

              break;
            }
          }
        }
      }

      window.addEventListener("message", receiveCode, false);
    }

    // Step 2 transactions
    var transactionTables = document.getElementsByClassName('transactions-table');
    if (transactionTables.length) {
      Array.from(transactionTables).map(table => {
        Array.from(table.rows).map(row => {
          row.addEventListener('click', event => {
            if (event.target.type != 'checkbox') {
              var checkbox = row.cells[0].getElementsByTagName('input')[0];
              checkbox.checked = !checkbox.checked;
            }
          });
        })
      })
    }
  }

  // Retrieve and populate company data
  var companySearchForm = document.getElementById('company_search');
  if (companySearchForm) {
    var billingInfoForm = document.querySelector('.new-company-billing-info');

    companySearchForm.addEventListener('submit', (event) => {
      event.preventDefault();

      const params = { company_name: companySearchForm.elements['company_name'].value };
      const paramString = new URLSearchParams(params);

      fetch(`${companySearchForm.action}?${paramString.toString()}`)
        .then(respJSON => respJSON.json())
        .then(resp => {
          switch(resp.status) {
            case 200: {
              var companyInfo = resp.company;
              billingInfoForm.elements['billing_info[company_number]'].value = companyInfo.id;
              billingInfoForm.elements['billing_info[name]'].value = companyInfo.name;
              billingInfoForm.elements['billing_info[address]'].value = companyInfo.address;

              break;
            }
            case 404: {
              break;
            }
            // case 'error' : {
            //   alert(resp.message);
            //   break;
            // }
          }
        })
        .catch(error => alert(error));
    })
  }
}, false);
