import React from "react"
import PropTypes from "prop-types"
import { CoffeeLoading } from 'react-loadingg';
import CheckForm from './CheckForm';
import CheckAdditionalForm from './CheckAdditionalForm';
import CountrySelect from './CountrySelect';
import { TextField } from "@material-ui/core";
import { AnimatedCheckmark, AnimatedCancelmark } from "./animated-icons";

import I18n from '../packs/i18n/translations';
I18n.translations || (I18n.translations = {});

const parse = require('html-react-parser');
const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class PropertyCheck extends React.Component {
  constructor(props) {
    super(props);

    window.addEventListener("message", this.receiveCode, false);

    this.state = {
      locale: this.props.locale || 'en',
      loading: false,
      iframeState: 0,
      callbackUrl: this.props.callbackUrl,
      step: 0,
      tenant: { email: '' },
      country: 'NO',
      loadingText: I18n.t('properties.show.loading_text.default'),
    };

    this.handleUserInfoSubmit = this.handleUserInfoSubmit.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  createCheck = () => {
    fetch(this.props.property.checkUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
              tenant_name: this.state.tenant.name,
              property_id: this.props.property.id,
              tenant: this.state.tenant,
              authenticity_token: this.props.authenticityToken,
            })
    })
    .then(respJSON => respJSON.json())
    .then(resp => {
      switch(resp.status) {
        case 'created':
        case 'exists': {
          this.setState({ checkId: resp.id });
          break;
        }
        case 'error': {
          alert(resp.message);
          break;
        }
      }
    })
    .catch(err => console.log(err));
  }

  receiveCode = (event) => {
    if (event.origin === 'https://link.tink.com') {
      console.log(event);
      const { type, data, error } = JSON.parse(event.data);

      switch(type) {
        case 'status': {
          // TODO: add some fancy things?
          this.setState({ loading: data.loading });
          break;
        }
        case 'application-event': {
          // TODO: Add fancy processing here
          // this.setState({ loading: data.data.event !== "INITIALIZED" }); // "PROVIDER_AUTHENTICATION_INITIALIZED", "AUTHENTICATION_SUCCESSFUL"
          if (data.event === 'PROVIDER_AUTHENTICATION_INITIALIZED') { this.setState({ loadingText: I18n.t('properties.show.loading_text.auth') }) }
          break;
        }
        case 'code': {
          let code = data; // data.type sould be 'code'
          this.setState({ loading: true, loadingText: I18n.t('properties.show.loading_text.data_processing') })

          if (this.props.callbackUrl != 'undefined' && this.props.callbackUrl != null) {
            let callbackLink = `${this.props.callbackUrl}/checks/${this.state.checkId}/verify_callback?code=${code}`;

            fetch(callbackLink)
              .then(respJSON => respJSON.json())
              .then(resp => {
                switch(resp.status) {
                  case 'ok': {
                    this.setState({ loading: false, step: 2 })
                    break;
                  }
                  case 'error' : {
                    alert(resp.message);
                    break;
                  }
                }
              })
          }

          break;
        }
        case 'error': {
          if (error.status == 'USER_CANCELLED') { this.setState({ step: 3, status: 'cancelled', loading: false }) }
          break;
        }
      }
    }
  }

  onAccountClaim = (event) => {
    event.preventDefault();

    fetch('/tenants/claim_account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
              tenant: {
                name: this.state.tenant.name,
                email: this.state.tenant.email,
              },
              check: {
                id: this.state.checkId,
              },
              authenticity_token: this.props.authenticityToken,
            })
    })
    .then(respJSON => respJSON.json())
    .then(resp => {
        switch(resp.status) {
          case 'created': {
            this.setState({ accountClaimed: true });
            break;
          }
          case 'error': {
            alert(resp.message);
            break;
          }
        }
      })
  }

  handleTenantInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ tenant: {...this.state.tenant, [name]: value} });
  }

  updateCheck = (inputs) => {
    // console.log(inputs);

    fetch(`${this.props.property.checkUrl}/${this.state.checkId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
              check: inputs,
              authenticity_token: this.props.authenticityToken,
            }),
    }).then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }

      return respJSON.json()
    })
    .then(resp => {
      switch (resp.status) {
        case 'updated': {
          this.setState({ step: 3, status: 'completed' });
          break;
        }
        case 'error': {
          alert(resp.message);
          break;
        }
      }
    })
    .catch(error => alert(error));
  }

  renderLoading() {
    if (this.state.loading) {
      return (
        <div className="justify-content-center align-items-center text-center d-flex flex-column" id="property_check_loading">
          <div className="d-flex" style={{ minHeight: "100px" }}>
            <CoffeeLoading color="#4579aa" />
          </div>
          <div>
            <p>{ this.state.loadingText }</p>
          </div>
        </div>
      )
    }
  }

  handleUserInfoSubmit(userData, event) {
    event.preventDefault();

    this.setState({ tenant: {...this.state.tenant, ...userData}, step: 1, loading: true }, this.createCheck);
  }

  handleCountryChange(value) {
    this.setState({ country: value })
  }

  renderCheckForm() {
    if (this.state.step === 0) {
      return (
        <React.Fragment>
          <p> { parse(I18n.t('property_into', { name: this.props.property.name, scope: 'properties.show' })) } </p>
          <p> { parse(I18n.t('info_sharing', { scope: 'properties.show' })) } </p>
          <p> { I18n.t('cancellability', { scope: 'properties.show' }) } </p>
          <CheckForm onSubmit={ this.handleUserInfoSubmit } />
        </React.Fragment>
      )
    }
  }

  renderAISP() {
    if (this.state.step === 1) {
      return (
        <React.Fragment>
          <p> { parse(I18n.t('properties.show.steps.step2.tips.subtitle')) } </p>
          <CountrySelect
            label={ I18n.t('properties.show.steps.step2.select_country') }
            value={ this.state.country }
            options={ Object.entries(I18n.t('properties.show.steps.step2.tink_countries')).map((entry) => [entry[0], <span><i className={ `flag-icon flag-icon-${entry[0].toLowerCase()} mr-2` } /> { entry[1] }</span>]) }
            onChange={ this.handleCountryChange }
          />
          <div className="d-flex justify-content-center mt-4">
            <iframe src={ `${this.props.bankIframeUrl}&market=${this.state.country}` } className="mx-auto" width="100%" height="450" frameBorder="0" />
          </div>
        </React.Fragment>
      )
    }
  }

  renderAdditionalInfo() {
    if (this.state.step === 2) {
      return (
        <CheckAdditionalForm fileUploadUrl={ this.props.fileUploadUrl } onSubmit={ this.updateCheck } />
      )
    }
  }

  renderThankYou() {
    if (this.state.step === 3) {
      switch (this.state.status) {
        case 'completed': {
          return (
            <React.Fragment>
              <div className="d-flex justify-content-center my-4">
                <AnimatedCheckmark timeout={ 800 } />
              </div>
              { parse(I18n.t('properties.show.steps.step3.header.completed')) }
              <p>{ I18n.t('properties.show.steps.step3.claim_account.text') }</p>
              { this.renderTenantForm() }
              <button className={ `btn btn-success btn-block mb-4 ${this.state.showTenantForm ? 'd-none' : '' }` } onClick={ () => this.setState({ showTenantForm: true }) }>{ I18n.t('properties.show.steps.step3.claim_account.button') }</button>
            </React.Fragment>
          )
          break;
        }
        case 'cancelled': {
          return (
            <React.Fragment>
              <div className="d-flex justify-content-center my-4">
                <AnimatedCancelmark />
              </div>
              { parse(I18n.t('properties.show.steps.step3.header.cancelled')) }
              <p>{ I18n.t('properties.show.steps.step3.restart.text') }</p>
              <button className="btn btn-primary btn-block mb-4" onClick={ () => window.location.reload() }>{ I18n.t('properties.show.steps.step3.restart.button') }</button>
            </React.Fragment>
          )
          break;
        }
      }
    }
  }

            // <label>{ I18n.t('tenants.email') }</label>
            // <input className="form-control" type="text" name="email" value={ this.state.tenant.email } onChange={ this.handleTenantInput } />
  renderTenantForm() {
    if (this.state.accountClaimed) {
      return (
        <p className="text-success">{ I18n.t('properties.show.steps.step3.claim_account.success') }</p>
      )
    }

    if (this.state.showTenantForm) {
      return (
        <form onSubmit={ this.onAccountClaim }>
          <div className="form-group">
            <TextField fullWidth label={ I18n.t('tenants.email') } name="email" variant="outlined" value={ this.state.tenant.email } onChange={this.handleTenantInput} />
          </div>
          <input className="btn btn-success btn-block mb-4" type="submit" value={ I18n.t('properties.show.steps.step3.claim_account.button') } disabled={ !emailPattern.test(this.state.tenant.email) } />
        </form>
      )
    }
  }

  render () {
    I18n.locale = this.state.locale;

    return (
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <div className="property-check bg-white">
            <div className="row">
              { this.renderLoading() }
              <div className="col-lg-8 offset-lg-2 col-10 offset-1">
                <h1 className="mt-4 text-center">
                  { I18n.t('properties.show.welcome_title') } { this.state.tenant.name || I18n.t('properties.show.welcome_name_placeholder') },
                </h1>
                { this.renderCheckForm() }
                { this.renderAISP() }
                { this.renderAdditionalInfo() }
                { this.renderThankYou() }
              </div>
            </div>
          </div>
          <div className="mt-4 px-4">
            <a href="#" className={`font-14 border-bottom text-dark pb-1 ${ this.state.locale === 'en' && "border-info"} mr-2` || ''} onClick={ () => this.setState({ locale: 'en' }) }>English</a>
            <a href="#" className={`font-14 border-bottom text-dark pb-1 ${ this.state.locale === 'nb' && "border-info"} mr-2` || ''} onClick={ () => this.setState({ locale: 'nb' }) }>Bokmål</a>
          </div>
        </div>
      </div>
    );
  }
}

PropertyCheck.propTypes = {
  authenticityToken: PropTypes.string,
  bankIframeUrl: PropTypes.string,
  callbackUrl: PropTypes.string,
  locale: PropTypes.string,
  property: PropTypes.object,
  fileUploadUrl: PropTypes.string,
};
export default PropertyCheck
