import React from "react"

export default class DropzoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      name: '',
      address: '',
      hideOtherFields: true,
      privacyAccepted: false,
      termsAccepted: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render () {
    return (
      <input className="dropzone-direct-upload" name={ this.props.name } />
    )
  }
}
