import React from "react"
import PropTypes from "prop-types"

class PriceRequest extends React.Component {
  constructor(props) {
    super(props);

    window.addEventListener("message", this.receiveCode, false);

    this.state = {
      loading: false,
      iframeState: 0,
      callbackUrl: this.props.callbackUrl,
      tinkOverlay: true,
      submitButtonEnabled: true,
    };
  }

  addAccount = (event) => {
    event.preventDefault();

    this.setState({ eligible: undefined });
  }

  createPriceRequest = (event) => {
    event.preventDefault();
    // console.log(event);

    this.setState({ submitButtonEnabled: false });
    var data = new FormData(event.target);
    fetch(this.props.formSubmitUrl, {
      method: 'POST',
      body: data,
    }).then(respJSON => respJSON.json())
    .then(resp => {
      switch(resp.status) {
        case 'ok': {
          this.setState({ id: resp.price_request.id, callbackUrl: resp.price_request.add_account_url })
          break;
        }
        case 'error' : {
          alert(resp.message);
          this.setState({ submitButtonEnabled: true });
          break;
        }
      }
    });
  }

  receiveCode = (event) => {
    if (event.origin === 'https://link.tink.com') {
      let data = JSON.parse(event.data);
      switch(data.type) {
        case 'status': {
          // TODO: add some fancy things?
          this.setState({ tinkOverlay: data.data.loading });
          // console.log(data);
          break;
        }
        case 'code': {
          let code = data.data; // data.type sould be 'code'
          this.setState({ loading: true })

          if (this.state.callbackUrl != 'undefined' && this.state.callbackUrl != null) {
            let callbackLink = `${this.state.callbackUrl}?code=${code}`;

            fetch(callbackLink)
              .then(respJSON => respJSON.json())
              .then(resp => {
                // resultLoadingContainer.classList.add('d-none');
                switch(resp.status) {
                  case 'ok': {
                    this.setState({ loading: false, eligible: resp.price_request.eligible, viewResultUrl: resp.price_request.url, callbackUrl: resp.price_request.add_account_url })
                    // if (resp.price_request.eligible) {
                    //   resultApprovedContainer.classList.remove('d-none');
                    //   let certificateButton = resultApprovedContainer.querySelector('a.btn')[0];
                    //   certificateButton.setAttribute('href', resp.price_request.url);
                    // } else {
                    //   resultDecliinedContainer.classList.remove('d-none');
                    //   priceReductionBankIframeContainer.dataset.callbackUrl = resp.price_request.add_account_url;
                    // }
                    break;
                  }
                  case 'error' : {
                    alert(resp.message);
                    break;
                  }
                }
              })
          }

          break;
        }
      }
    }
  }

  reloadIframe = (event) => {
    event.preventDefault();

    this.setState({ iframeState: this.state.iframeState + 1, tinkOverlay: true });
  }

  renderLoading() {
    if (this.state.loading) {
      return (
        <div className="justify-content-center align-items-center text-center animated fadeIn d-flex align-items-center" id="result_loading">
          <div>
            <img src={ this.props.loadingImageSrc } />
            <h3>Analyzing transactions</h3>
            <p>Please wait a little while...</p>
          </div>
        </div>
      )
    }
  }

  renderForm() {
    if (this.state.id === undefined) {
      return (
        <React.Fragment>
          <div className="text-muted mb-3">Start by filling in all the fields below with your current rent (your rental agreement)</div>
          <form onSubmit={ this.createPriceRequest } className="simple_form new_price_request" id="new_price_request" noValidate="novalidate" acceptCharset="UTF-8">
            <input type="hidden" name="authenticity_token" value={ this.props.authenticityToken } />

            <div className="form-group string required price_request_tenant_name">
              <label className="string required">Your name</label>
              <input className="form-control string required" placeholder="Erlend Johannessen" type="text" name="price_request[tenant_name]" id="price_request_tenant_name" />
            </div>
            <div className="form-group string required price_request_tenant_address">
              <label className="string required">Your address</label>
              <input className="form-control string required" placeholder="Daniel Hansens gate 9, 5008 Bergen" type="text" name="price_request[tenant_address]" id="price_request_tenant_address" />
            </div>
            <div className="form-group integer required price_request_tenant_rent_price">
              <label className="integer required">Current rent price</label>
              <input className="form-control numeric integer required" placeholder="7500" type="number" step="1" name="price_request[tenant_rent_price]" id="price_request_tenant_rent_price" />
            </div>

            <button className="btn btn-block btn-green" disabled={ !this.state.submitButtonEnabled } >Start</button>
            <h6 className="text-center mt-2">
              When click "Start" you agree with the <a href="https://sprove.me/privacy_policy" target="_blank">Privacy policy</a>
            </h6>
            <h6 className="text-center mt-2">Your data is only used to make the certificate to share with the landlord. We don't store any data other than presented on the certificate.</h6>
          </form>
        </React.Fragment>
      )
    }
  }
          // <input type="submit" name="commit" value="Start" className="btn btn btn-rounded btn-block btn-success" data-disable-with="Start" disabled={ !this.state.submitButtonEnabled } />

  renderStart() {
    if (this.state.eligible === undefined && !this.state.loading && this.state.id !== undefined) {
      return (
        <React.Fragment>
          <div className="animated fadeIn justify-content-center align-items-center text-center" id="tink_iframe_tenant">
            <iframe className="mx-auto" src={this.props.bankIframeUrl} height="500" frameBorder="0" key={ this.state.iframeState } />
            <a href="#" onClick={ this.reloadIframe } className="btn btn-link">Click here if "something went wrong"</a>
          </div>
          {
            this.state.tinkOverlay &&
              <div className="tinkOverlay">
                <div className="text align-items-center">
                  <img src={ this.props.loadingImageSrc } />
                  <p>Loading...</p>
                </div>
              </div>
          }
        </React.Fragment>
      )
    }
  }

  renderStatus() {
    switch(this.state.eligible) {
      case true: {
        return (
          <div className="justify-content-center align-items-center text-center animated bounceIn" id="result_declined">
            <img src={ this.props.approvedImageSrc } className='result-icon' />
            <h3>Awesome news!</h3>
            <p>We've found significant ground for price reductions.</p>
            <p>Now you can share the results with your landlord.</p>
            <a className="btn btn-green btn-block" href={this.state.viewResultUrl}>Share with the landlord</a>
          </div>
        )
        // break;
      }
      case false: {
        return (
          <div className="justify-content-center align-items-center text-center animated bounceIn" id="result_declined">
            <img src={ this.props.rejectedImageSrc } className='result-icon' />
            <h3>Unfortunately, we didn't find anough grounds for the discount</h3>
            <p>It could be we didn't find any rental payments made regulary from the account or the account you use has no income transactions.</p>
            <a className="btn btn-green btn-block" href="#" onClick={ this.addAccount }>Add another primary account</a>
          </div>
        )
        // break;
      }
    }
  }

  render () {
    return (
      <div className="align-items-center" id="price_request_wrapper">
        <h4 className="card-title">Get grounds to ask your landlord to lower down your rent during the COVID-19 outbreak</h4>
        { this.renderLoading() }
        { this.renderForm() }
        { this.renderStart() }
        { this.renderStatus() }
      </div>
    );
  }
}

PriceRequest.propTypes = {
  id: PropTypes.number,
  bankIframeUrl: PropTypes.string,
  callbackUrl: PropTypes.string,
  loadingImageSrc: PropTypes.string,
  approvedImageSrc: PropTypes.string,
  rejectedImageSrc: PropTypes.string,
};
export default PriceRequest
