import React from "react"
import I18n from 'packs/i18n/translations';
import DirectUploadController, { onDropzoneFileDrop, initDropzone, formDataToMap } from 'packs/direct_upload_controller';

export default class DocUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.dropzones = initDropzone(this.props.fileUploadUrl);
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.name === 'termsAccepted' || target.name === 'privacyAccepted' ? target.checked : target.value;
    const name = target.name;

    if (name == 'phone') { value = value.replace(/\D/,'').substring(0,8) }

    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event = null) => {
    if (event) {
      event.preventDefault();

      event.target.disabled = true;
    }

    if (this.state.processing) { return }

    var source = this.dropzones.shift();
    while (source !== undefined && source.dropzone.getAcceptedFiles().length == 0) {
      source = this.dropzones.shift();
    }

    if (source) {
      this.setState({ processing: true });

      source.dropzone.getAcceptedFiles().map(file => {
        var uploadController = new DirectUploadController(source.dropzone, source.inputTarget, source.element, file);
        uploadController.start();
      });

      source.dropzone.on('queuecomplete', (event) => {
        this.setState({ processing: false });

        // console.log((new FormData(this.formRef.current)).entries());
        this.handleSubmit();
      });
    } else {
      // console.log(new FormData(this.formRef.current).get('check'));
      this.props.onSubmit(formDataToMap(new FormData(this.formRef.current)));
    }
  }

  isSubmitEnabled() {
    return this.state.privacyAccepted && this.state.termsAccepted && this.state.address.length > 8 && this.state.name.length > 4;
  }

  render() {
    return (
      <form ref={ this.formRef }>
        <div id="check_references">
          <label>{ I18n.t('checks.references') }</label>
          <input multiple="multiple" accept="application/pdf" data-target="#check_references .dropzone" data-max-files="5" data-max-filesize="10" className="string optional disabled d-none dropzone-direct-upload" disabled="disabled" type="file" name="references[]" />
          <div className="dropzone dropzone-default dropzone-brand dz-clickable">
            <div className="dropzone-msg dz-message needsclick">
              <h3 className="dropzone-msg-title">{ I18n.t('checks.reference_upload.header') }</h3>
              <span className="dropzone-msg-desc">{ I18n.t('checks.reference_upload.subheader') }</span>
            </div>
          </div>
        </div>
        <div id="check_documents" className="mt-4">
          <label>{ I18n.t('checks.documents') }</label>
          <input multiple="multiple" data-target="#check_documents .dropzone" data-max-files="10" data-max-filesize="10" className="string optional disabled d-none dropzone-direct-upload" disabled="disabled" type="file" name="documents[]" />
          <div className="dropzone dropzone-default dropzone-brand dz-clickable">
            <div className="dropzone-msg dz-message needsclick">
              <h3 className="dropzone-msg-title">{ I18n.t('checks.document_upload.header') }</h3>
              <span className="dropzone-msg-desc">{ I18n.t('checks.document_upload.subheader') }</span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
