import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;

const MultipleInputPattern = /\[\]/;

export const onDropzoneFileDrop = (event) => {
  var imageUrl = event.dataTransfer.getData('URL');
  var fileName = imageUrl.split('/').pop();

  // set the effectAllowed for the drag item
  event.dataTransfer.effectAllowed = 'copy';

  function getDataUri(url, callback) {
    var image = new Image();

    image.onload = function() {
      var canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

      canvas.getContext('2d').drawImage(this, 0, 0);

      // Get raw image data
      // callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

      // ... or get as Data URI
      callback(canvas.toDataURL('image/jpeg'));
    };

    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  }

  function dataURItoBlob(dataURI) {
    var byteString,
        mimestring

    if (dataURI.split(',')[0].indexOf('base64') !== -1) {
      byteString = atob(dataURI.split(',')[1])
    } else {
      byteString = decodeURI(dataURI.split(',')[1])
    }

    mimestring = dataURI.split(',')[0].split(':')[1].split(';')[0]

    var content = new Array();
    for (var i = 0; i < byteString.length; i++) {
      content[i] = byteString.charCodeAt(i)
    }

    return new Blob([new Uint8Array(content)], {
      type: mimestring
    });
  }

  getDataUri(imageUrl, function(dataUri) {
    var blob = dataURItoBlob(dataUri);
    blob.name = fileName;
    myDropzone.addFile(blob);
  });
};

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export default class DirectUploadController {
  constructor(dropzone, inputTarget, element, file) {
    this.directUpload = new DirectUpload(file, dropzone.options.url, this);
    this.dropzone = dropzone;
    this.inputTarget = inputTarget;
    this.element = element;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        // removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.inputTarget.name;
    insertAfter(input, this.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.dropzone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.dropzone.emit("error", this.file, error);
    this.dropzone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.dropzone.emit("success", this.file);
    this.dropzone.emit("complete", this.file);
  }
}

export const formDataToMap = (data) => {
  return Array.from(data.entries()).reduce((map, obj) => {
    if (MultipleInputPattern.test(obj[0])) {
      let key = obj[0].replace(MultipleInputPattern, '');
      data = map[key] || [];
      data.push(obj[1]);

      map[key] = data;
    } else {
      map[obj[0]] = obj[1];
    }

    return map;
  }, {});
};

export const initDropzone = (url, className = 'dropzone-direct-upload') => {
  // Init Dropzone
  console.log('init');
  var dropzoneDirectUploads = document.getElementsByClassName(className);

  if (dropzoneDirectUploads.length) {
    function onMaxFilesExceeded (file) {
      this.removeFile(file);
    }

    var dropzoneSources =
      Array.from(dropzoneDirectUploads).map(inputTarget => {
        var dropzoneEl = document.querySelector(inputTarget.dataset.target),
            options = inputTarget.dataset;

        if (dropzoneEl.dropzone) {
          return {
            dropzone: dropzoneEl.dropzone,
            element: dropzoneEl,
            inputTarget: inputTarget,
          };
        }

        var thisDropzone = new Dropzone(options.target, { // Make the whole body a dropzone
            url: url, // Set the url for your upload script location
            // parallelUploads: options.parallelUploads || 20,
            // previewTemplate: preview Template,
            maxFiles: options.maxFiles || 1,
            maxFilesize: options.maxFilesize || 100, // Max filesize in MB
            autoQueue: false, // Make sure the files aren't queued until manually added
            // previewsContainer: id + " .dropzone-items", // Define the container to display the previews
            // clickable: id, // + " .dropzone-select" // Define the element that should be used as click trigger to select files.
            acceptedFiles: inputTarget.accept,
            addRemoveLinks: true,
            // maxfilesexceeded: onMaxFilesExceeded,
            init: function() {
              var myDropzone = this;

              this.on("drop", onDropzoneFileDrop);
          }
        });
        thisDropzone.on('maxfilesexceeded', onMaxFilesExceeded);

        return {
          dropzone: thisDropzone,
          element: dropzoneEl,
          inputTarget: inputTarget,
        };
      });

    return dropzoneSources;
  }
}
