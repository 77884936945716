import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-buttons-bs4";
import 'select2';

// Default classes
$.extend( $.fn.dataTable.ext.classes, {
  // sWrapper:      "dataTables_wrapper dt-bootstrap4",
  sFilterInput:  "form-control",
  // sLengthSelect: "custom-select custom-select-sm form-control form-control-sm",
  // sProcessing:   "dataTables_processing card",
  // sPageButton:   "paginate_button page-item"
} );

$.extend( true, $.fn.dataTable.Buttons.defaults, {
  dom: {
    button: {
      className: 'btn',
    },
  },
} );

$( () => {
  const $propertiesTable = $('#properties'), $myPropertiesTable = $('#inactive_properties');

  if ($propertiesTable.length) {
    const propertiesDT =
      $propertiesTable.DataTable({
        ajax: {
          url: "/properties.json",
          dataSrc: 'properties',
          data: d => {
            d.filter = 'active';
          },
        },
        deferRender: true,
        dom: 'frt<"d-flex justify-content-between flex-wrap"i<"mt-2"l>p>',
        columns: [
          { data: "name" },
          { data: "address" },
          { data: "rent", type: 'num-fmt' },
          { data: "deposit", type: 'num-fmt' },
          { data: "applications_count", type: 'num' },
          {
            data: { _: 'updated_at', sort: 'updated_at_timestamp' }
          },
          { data: "deactivate_url" },
        ],
        columnDefs: [
          {
            targets: 0,
            data: "name",
            render: function ( data, type, row, meta ) {
              return data && `<div class="property-name" title="${data}">${data}</div>`;
            }
          },
          {
            targets: -1,
            orderable: false,
          },
        ],
        order: [[5, 'desc']]
      });

    $propertiesTable.on('click', 'tbody tr', function () {
      var data = propertiesDT.row( this ).data();
      window.location = data.url;
    } );
  };

  if ($myPropertiesTable.length) {
    $myPropertiesTable.dataTable({
      ajax: {
        url: "/properties.json",
        dataSrc: 'properties',
        data: d => {
          d.filter = 'inactive';
        },
      },
      deferRender: true,
      dom: 'frt<"d-flex justify-content-between flex-wrap"i<"mt-2"l>p>',
      columns: [
          { data: "name" },
          { data: "address" },
          { data: "rent", type: 'num-fmt' },
          { data: "deposit", type: 'num-fmt' },
          { data: "activate_url" },
        ],
        columnDefs: [
          {
            targets: 0,
            data: "name",
            render: function ( data, type, row, meta ) {
              return data && `<div class="property-name" title="${data}">${data}</div>`;
            }
          },
          {
            targets: -1,
            orderable: false,
            // render: function ( data, type, row, meta ) {
            //   return data && `<div class="property-name" title="${data}">${data}</div>`;
            // }
          },
        ],
    });
  };

  // $(document).on('shown.bs.tab', '.properties_wrapper .nav-tabs a', (event) => {
  //   // $('.dataTable').each(() => {
  //   //   console.log($(this).DataTable());
  //   //   $(this).DataTable().columns.adjust().draw();
  //   // });
  //   // $('a[data-toggle="tab"]').on( 'shown.bs.tab', function (e) {
  //     // $.fn.dataTable.tables( {visible: true, api: true} ).columns.adjust();
  //   // } );
  // });

  // $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
  //   console.log('erwr');
  //   $($.fn.dataTable.tables(true)).DataTable()
  //        .columns.adjust()
  //        .responsive.recalc();
  //   // $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust().draw();
  // });

  ////////////////////////////////////////////
  // Parse ad
  ////////////////////////////////////////////

  const adForms = document.querySelectorAll('.parse-ad-form');
  const enableInputs = (form) => {
    [...form.querySelectorAll('input')].map(inputEl => {
      inputEl.disabled = false;
    });
  }

  if (adForms) {
    Array.from(adForms).map(adForm => {
      let propertyForm = adForm.parentElement.querySelector('form.property');

      adForm.addEventListener('submit', (event) => {
        event.preventDefault();

        [...propertyForm.querySelectorAll('input')].map(inputEl => {
          inputEl.disabled = true;
        });

        fetch(adForm.action, {
          method: 'POST',
          body: new FormData(adForm)
        }).then(resp => resp.json())
        .then(data => {
          propertyForm.querySelector('input[name="property[name]"]').value = data.name;
          propertyForm.querySelector('input[name="property[rent]"]').value = data.rent;
          propertyForm.querySelector('input[name="property[deposit]"]').value = data.deposit;
          propertyForm.querySelector('input[name="property[ad_code]"]').value = new FormData(adForm).get('user_info[ad_code]');

          // propertyForm.querySelector('select[name="property[address]"]').value = data.address;
          const newOption = new Option(data.address, data.address, true, true);
          $(propertyForm.querySelector('select[name="property[address]"]')).append(newOption).trigger('change');

          enableInputs(propertyForm);
        })
        .catch(error => enableInputs(propertyForm))
      })
    })
  }

  ////////////////////////////////////////////
  // Letting applciations
  ////////////////////////////////////////////
  const $propertyApplicationsTable = $('#letting_applications_datatable');
  const checksDT =
    $propertyApplicationsTable.DataTable({
      dom: '<"d-flex flex-wrap"f<"ml-2"B>> rt<"d-flex justify-content-between flex-wrap"i<"mt-2"l>p>',
      lengthMenu: [ 5, 10, 25, 50, 100 ],
      pageLength: 5,
      order: [[ 5, "desc" ]],
      columnDefs: [
        {
          targets: -1,
          visible: false,
        },
        {
          targets: [1, 2, -1],
          orderable: false,
        },
      ],
      buttons: [
        {
          text: 'Verified',
          className: 'btn btn-success',
          action: function ( e, dt, node, config ) {
            dt.column('.check-status').search("1").draw();
          }
        },
        {
          text: 'All',
          className: 'btn btn-primary ml-2',
          action: function ( e, dt, node, config ) {
            dt.column('.check-status').search('').draw();
          }
        }
      ],
    });

  $propertyApplicationsTable.on('click', 'tbody tr', function (event) {
    if (event.target.tagName.toLowerCase() !== 'a') {
      var data = Object.values(checksDT.row( this ).data());
      // console.log(data);
      window.location = data[data.length - 1];
    }
  } );

  ////////////////////////////////////////////
  // Letting applications on tenants dashboard
  ////////////////////////////////////////////
  const $tenantApplicationsTable = $('#tenant_letting_applications_datatable');
  const tenantApplicationsDT =
    $tenantApplicationsTable.DataTable({
      dom: '<"d-flex flex-wrap"f> rt<"d-flex justify-content-between flex-wrap"i<"mt-2"l>p>',
      lengthMenu: [ 5, 10, 25, 50, 100 ],
      pageLength: 5,
      order: [[ 4, "desc" ]],
      columnDefs: [
        {
          targets: -1,
          visible: false,
        },
        {
          targets: [1, -2, -1],
          orderable: false,
        },
      ],
    });

  // TODO: add letting application apgge for tenants
  // $tenantApplicationsTable.on('click', 'tbody tr', function (event) {
  //   if (event.target.tagName.toLowerCase() !== 'a') {
  //     var data = Object.values(tenantApplicationsDT.row( this ).data());
  //     window.location = data[data.length - 1];
  //   }
  // } );

  ////////////////////////////////////////////
  // select2 extension
  ////////////////////////////////////////////
  $.fn.select2.amd.define('select2/data/geonorgeAutocompleteAdapter', ['select2/data/array', 'select2/utils'],
    function (ArrayAdapter, Utils) {
      function GeonorgeAutocompleteDataAdapter ($element, options) {
        GeonorgeAutocompleteDataAdapter.__super__.constructor.call(this, $element, options);
      }

      Utils.Extend(GeonorgeAutocompleteDataAdapter, ArrayAdapter);

      GeonorgeAutocompleteDataAdapter.prototype.query = function (params, callback) {
          var returnSuggestions = function(predictions, status)
          {
              var data = { results: [{ id: params.term, text: params.term }] };
              // if (status != google.maps.places.PlacesServiceStatus.OK) {
              //     callback(data);
              // }
              for(var i = 0; i< predictions.length; i++)
              {
                  data.results.push({ id: predictions[i].description, text: predictions[i].description });
              }
              // data.results.push({id:' ', text: 'Powered by Geonorge', disabled: true});
              callback(data);
          };

          if(params.term && params.term != '')
          {
              // var service = new google.maps.places.AutocompleteService();
              // service.getPlacePredictions({ input: params.term }, returnSuggestions);
            fetch(`https://ws.geonorge.no/adresser/v1/sok?sok=${params.term}`)
            .then(resp => resp.json())
            .then(resp => {
              returnSuggestions(
                resp.adresser.map(address => {
                  if (address.bruksenhetsnummer?.length > 0) {
                    return address.bruksenhetsnummer.map(apartment => {
                      return { description: `${apartment} ${address.adressetekst} ${address.postnummer} ${address.poststed}` }
                    })
                  } else {
                    return { description: `${address.adressetekst} ${address.postnummer} ${address.poststed}` }
                  }
                }).flat());
            })
          } else {
            // console.log(this.container.dropdown.$search.val());
            const currentValue = this.container.val();
            this.container.dropdown.$search.val(currentValue);
            var data = { results: [{ id: currentValue, text: currentValue }] };
            // data.results.push({ id:' ', text: 'Powered by Geonorge', disabled: true });
            callback(data);
          }
      };
      return GeonorgeAutocompleteDataAdapter;
    }
  );
  function formatRepo (repo) {
    if (repo.loading) {
      return repo.text;
    }
    var markup = "<div class='select2-result-repository clearfix'>" +
       "<div class='select2-result-title'>" + repo.text + "</div>";
    return markup;
  }

  function formatRepoSelection (repo) {
    return repo.text;
  }

  var geonorgeAutocompleteAdapter = $.fn.select2.amd.require('select2/data/geonorgeAutocompleteAdapter');

  $('.select2-geonorge-autocomplete').select2({
      dataAdapter: geonorgeAutocompleteAdapter,
      placeholder: 'Search Address',
      escapeMarkup: (markup) => markup,
      minimumInputLength: 2,
      templateResult: formatRepo,
      templateSelection: formatRepoSelection
    });
});
