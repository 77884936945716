import React from "react"
import CountrySelect from './CountrySelect';
import { CoffeeLoading } from 'react-loadingg';

const parse = require('html-react-parser');
import I18n from '../packs/i18n/translations';

export default class TinkLinkForm extends React.Component {
  constructor(props) {
    super(props);

    window.addEventListener("message", this.receiveCode, false);
    this.state = {
      country: 'NO',
      loading: true,
      loadingText: I18n.t('properties.show.loading_text.default'),
    };
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveCode, false);
  }

  handleCountryChange = (value) => {
    this.setState({ country: value });
  }

  receiveCode = (event) => {
    if (event.origin === 'https://link.tink.com') {
      console.log(event.data);
      const { type, data, error } = JSON.parse(event.data);

      switch(type) {
        case 'status': {
          // TODO: add some fancy things?
          this.setState({ loading: data.loading });
          break;
        }
        case 'application-event': {
          // TODO: Add fancy processing here
          // this.setState({ loading: data.data.event !== "INITIALIZED" }); // "PROVIDER_AUTHENTICATION_INITIALIZED", "AUTHENTICATION_SUCCESSFUL"
          // USER_CANCELLED
          switch (data.event) {
            case 'PROVIDER_AUTHENTICATION_INITIALIZED':
            case 'AUTHENTICATION_SUCCESSFUL': {
              this.setState({ loadingText: I18n.t('properties.show.loading_text.auth') });
              break;
            }
            default: {
              this.setState({ loadingText: I18n.t('properties.show.loading_text.default') });
            }
          }
          break;
        }
        case 'code': {
          let code = data; // data.type sould be 'code'
          this.setState({ loading: true, loadingText: I18n.t('properties.show.loading_text.data_processing') });

          if (this.props.callbackUrl != 'undefined' && this.props.callbackUrl != null) {
            let callbackLink = `${this.props.callbackUrl}?code=${code}&tenant_name=${this.props.tenantName}`;

            fetch(callbackLink)
              .then(respJSON => respJSON.json())
              .then(resp => {
                switch(resp.status) {
                  case 'ok': {
                    this.setState({ loading: false });
                    this.props.onSubmit && this.props.onSubmit();

                    break;
                  }
                  case 'error' : {
                    alert(resp.message);
                    break;
                  }
                }
              });

          }

          break;
        }
        case 'error': {
          if (error.status == 'USER_CANCELLED') { this.setState({ step: 3, status: 'cancelled', loading: false }) }
          break;
        }
      }
    }
  }

  renderLoading() {
    if (this.state.loading) {
      return (
        <div className="justify-content-center align-items-center text-center d-flex flex-column" id="tink_link_loading">
          <div className="d-flex" style={{ minHeight: "125px" }}>
            <CoffeeLoading color="#4579aa" />
          </div>
          <div>
            <p>{ this.state.loadingText }</p>
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      <React.Fragment>
        <div className="position-relative">
          { this.renderLoading() }
          <p> { parse(I18n.t('properties.show.steps.step2.tips.subtitle')) } </p>
          <CountrySelect
            label={ I18n.t('properties.show.steps.step2.select_country') }
            value={ this.state.country }
            options={ Object.entries(I18n.t('properties.show.steps.step2.tink_countries')).map((entry) => [entry[0], <span><i className={ `flag-icon flag-icon-${entry[0].toLowerCase()} mr-2` } /> { entry[1] }</span>]) }
            onChange={ this.handleCountryChange }
          />
          <div className="d-flex justify-content-center mt-4">
            <iframe src={ `${this.props.bankIframeUrl}&market=${this.state.country}` } className="mx-auto" width="100%" height="450" frameBorder="0" />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
